function randint(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
function randbool() {
  return Math.random() >= 0.5;
}

var  docEl = document.documentElement,
  body = document.body,
  x = window.innerWidth || docEl.clientWidth || body.clientWidth,
  n = document.getElementById('nav'),
  p = document.getElementById('page');

// Typewriter
var tw_index = 0, tw_interval = null, tw_text = "";
function doTypeWriter(el) {
  if (tw_index < (tw_text.length)) {
    el.textContent += tw_text.charAt(tw_index);
    tw_index ++;
  } else {
    clearInterval(tw_interval);
  }
}
function typeWriter(el) {
  setTimeout(function(){
    tw_text = el.dataset.text;
    el.classList.add('visible');
    el.textContent = " ";
    tw_interval = setInterval(function(){
      doTypeWriter(el);
    }, 75);  
  }, 2000)
}

var initVignettes = function () {
  setTimeout(function () {
    var vignettes = document.querySelectorAll('.vignette');

    if (vignettes.length) {
      var page_width = document.querySelector('#page').clientWidth,
        text = document.querySelector('#text'),
        bcr = text.getBoundingClientRect(),
        text_height = bcr.height,
        text_width = bcr.width,
        scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop,
        text_top = parseInt(bcr.top) + scrollTop,
        section = parseInt(text_height / (vignettes.length - 1));

      var idx = 0;
      vignettes.forEach(function (vignette) {
        var is_portait = vignette.classList.contains('is_portrait'),
          w = is_portait ? 160 : 220,
          h = is_portait ? 220 : 160,
          t = 0;

        if (idx == 0) {
          // top right thumb
          t = randint(30, 180 + 8 * 16 - h) + "px";
          var left = randint(text_width + 30, Math.min(page_width - 30 - w, 1000));
          vignette.style.left = left + "px";
        } else {
          // other thumbs
          t = randint(text_top + 50 + section * (idx - 1), text_top + section * (idx - 1) + section - h - 30) + "px";
          var l_r = idx % 2 == 0 ? 'right' : 'left';
          if (l_r == 'left') {
            vignette.style.left = 0 - randint(1, 2) * 70 + "px";
          } else {
            vignette.style.left = parseInt(text_width) + randint(1, 2) * 70 - w + "px";
          }
        }
        vignette.style.width = w;
        vignette.style.top = t;
        vignette.style.visibility = 'visible';
        idx++;
      })

    }
  }, 100)
}

var initSlideshow = function (vignettes) {
  var slideshow = document.querySelector('.slideshow');
  if(slideshow){
    var flkty = new Flickity( slideshow, {
      wrapAround: true,
      pageDots: false,
      draggable: false
    });
    slideshow.addEventListener('click', function(e){
      if(e.target.matches('.flickity-button')){
        e.preventDefault();
        e.stopPropagation();
      }
    })
  }
}

var initVignettesAbout = function () {
  var vignettes = document.querySelectorAll('.vignette-about');
  if (vignettes.length) {
    var page_width = document.querySelector('#page').clientWidth,
      text = document.querySelector('#text'),
      bcr = text.getBoundingClientRect(),
      text_height = bcr.height,
      text_width = bcr.width,
      scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop,
      text_top = parseInt(bcr.top) + scrollTop,
      section = parseInt(text_height / (vignettes.length - 1));

    var idx = 0;
    vignettes.forEach(function (vignette) {
      var is_portait = vignette.classList.contains('is_portrait'),
        w = is_portait ? 160 : 220,
        h = is_portait ? 220 : 160,
        t = 0;
      // other thumbs
      var top_limit = text_top - 30 + section * idx;
      var bottom_limit = top_limit + section - h - 30;
      t = randint(top_limit, bottom_limit) + "px";
      var l_r = idx % 2 == 0 ? 'right' : 'left';
      if (l_r == 'left') {
        vignette.style.left = 0 - randint(1, 2) * 70 + "px";
      } else {
        vignette.style.left = parseInt(text_width) + randint(1, 2) * 70 - w + "px";
      }

      vignette.style.width = w;
      vignette.style.top = t;
      vignette.style.visibility = 'visible';
      idx++;

    })


  }
}



var actu_hover_timer;
var toggleH2Actus = function (mode) {
  var h2 = document.querySelector('#h2-actus');
  if (mode == 'show') {
    actu_hover_timer = setTimeout(function () {
      h2.style.opacity = 1;
    }, 300);
  } else {
    h2.style.opacity = 0;
    clearTimeout(actu_hover_timer);
  }
}

var vignettes_hover_timer;
var toggleVignettes = function (mode) {
  var vignettes = document.querySelector('#vignettes');
  if(vignettes == null){
    return;
  }
  if (mode == 'show') {
    vignettes_hover_timer = setTimeout(function () {
      vignettes.style.zIndex = 3;
    }, 300);
  } else {
    vignettes.style.zIndex = -1;
    clearTimeout(vignettes_hover_timer);
  }
}

var page = {
  init: function (is_home) {
    var is_home = is_home != false ? false : true;

    // vignettes actualités
    var asideTriggers = document.querySelectorAll('.aside-trigger');
    if (asideTriggers) {
      asideTriggers.forEach(function (el) {
        el.addEventListener('mouseover', function () {
          toggleH2Actus('hide');
        })
        el.addEventListener('mouseleave', function () {
          toggleH2Actus('show');
        })
        // tiroir actualités
        el.addEventListener('click', function () {
          var parent = this.parentElement;
          parent.classList.toggle('aside-visible');
        })
      })
    }

    // vignettes cachées au survol
    var text = document.querySelector('#text');
    if (text) {
      text.addEventListener('mouseover', function () {
        toggleVignettes('hide');
      })
      text.addEventListener('mouseleave', function () {
        toggleVignettes('show');
      })
    }

    // à propos
    var readmores = document.querySelectorAll('.readmore');
    readmores.forEach(function (el) {
      el.addEventListener('click', function (e) {
        e.preventDefault();
        this.style.display = "none";
        var next = this.nextElementSibling;
        if (next) next.style.display = "block";
      })
    })

    // recherche
    var q = document.querySelector('#q');
    if (q) {
      q.focus();
    }

    initVignettes();
    if (is_home) initSlideshow(true);
    initVignettesAbout();
    // mailchimp();

  }
}

var home = {
  init: function () {
    var h2 = document.querySelector('#h2-actus');
    if (h2) {
      typeWriter(h2);
    }
  }
}

var menu = {
  init: function () {
    // Menulink
    var menulink = document.querySelector('#menulink');
    menulink.addEventListener('click', function () {
      body.classList.add('menu-visible');

      setTimeout(function () {
        window.dispatchEvent(new Event('resize'));
        // lazySizes.autoSizer.checkElems();
      }, 250)
      body.classList.remove('menu-invisible');
    })

    // No Menulink
    var nomenulink = document.querySelector('#nomenulink');
    nomenulink.addEventListener('click', function () {
      setTimeout(function () {
        window.dispatchEvent(new Event('resize'));
        // lazySizes.autoSizer.checkElems();
      }, 250)

      body.classList.remove('menu-visible');
      body.classList.add('menu-invisible');
    })

    // recherche menu
    var searchlink = document.querySelector('#li-recherche a');
    var mq = document.querySelector('#mq');
    searchlink.addEventListener('click', function (e) {
      e.preventDefault();
      var li = this.parentElement;
      mq.focus();
      li.classList.toggle('active');
    })

    mq.addEventListener('blur', function () {
      document.querySelector('#li-recherche').classList.remove('active')
    });

    // sous-menus
    var submenustriggers = document.querySelectorAll('.submenu-trigger');
    submenustriggers.forEach(function (el) {
      el.addEventListener('click', function (e) {
        e.preventDefault();
        // $(this).toggleClass('active');
        var is_active = el.classList.add('active');
        if (is_active) {
          el.classList.remove('active');
          el.nextElementSibling.classList.remove('visible');
        } else {
          el.classList.add('active');
          el.nextElementSibling.classList.add('visible');
        }
      })
    })
  }
}


home.init();
menu.init();
page.init(false);

